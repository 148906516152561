<template>
    <div class="instruction full-width" v-if="translates && translates[langUrl]">
        <div
            class="instruction__main-image">
            <div
                v-if="!isDesktop"
                class="instruction__main-image__action instruction__main-image__action_back"
                @click="back()">
                <img src="../assets/BackArrow.svg" class="instruction__main-image__action__icon" />
            </div>
            <div class="instruction__main-image__opacity"></div>
            <div class="instruction__main-image__container">
                <div class="instruction__main-image__container__text">
                    <div class="instruction__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                    <div class="instruction__main-image__container__comment">{{ translates[langUrl].pageDescription[lang] }}</div>
                </div>
                <!-- <div v-if="pageTemplate.main.links" class="main-page__main-image__container__directions">
                    <template v-for="(link, index) in pageTemplate.main.links">
                        <div
                            :key="`main-links-${index}`"
                            class="instruction__main-image__container__direction"
                            @click="$router.push(link.link)">{{ link.title }}</div>
                    </template>
                </div> -->
            </div>
        </div>
        <div class="instruction__main-container">
            <div class="instruction__block">
                <div class="instruction__block__text-block">
                    <div v-if="isDesktop" class="instruction__block__text-block__step">1</div>
                    <div class="instruction__block__text-block__text">
                        <div style="display: flex">
                            <div v-if="!isDesktop" class="instruction__block__text-block__step">1</div>
                            <div class="instruction__block__text-block__title">{{ translates[langUrl].step1_title[lang] }}</div>
                        </div>
                        <!-- <div class="instruction__block__text-block__title">Выберите тур</div> -->
                        <div class="instruction__block__text-block__description">{{ translates[langUrl].step1_description[lang] }}</div>
                        <BaseButton
                            class="instruction__block__text-block__button"
                            @click="goTo('trip-list')"
                        >{{ translates[langUrl].button[lang] }}</BaseButton>
                    </div>
                </div>
                <div class="instruction__block__picture instruction__block__picture__main"></div>
            </div>
            <div class="instruction__block">
                <div class="instruction__block__text-block">
                    <div v-if="isDesktop" class="instruction__block__text-block__step">2</div>
                    <div class="instruction__block__text-block__text">
                        <div style="display: flex">
                            <div v-if="!isDesktop" class="instruction__block__text-block__step">2</div>
                            <div class="instruction__block__text-block__title">{{ translates[langUrl].step2_title[lang] }}</div>
                        </div>
                        <div class="instruction__block__text-block__description">{{ translates[langUrl].step2_description[lang] }}</div>
                    </div>
                </div>
                <div class="instruction__block__picture instruction__block__picture__chat"></div>
            </div>
            <div class="instruction__block">
                <div class="instruction__block__text-block">
                    <div v-if="isDesktop" class="instruction__block__text-block__step">3</div>
                    <div class="instruction__block__text-block__text">
                        <div style="display: flex">
                            <div v-if="!isDesktop" class="instruction__block__text-block__step">3</div>
                            <div class="instruction__block__text-block__title">{{ translates[langUrl].step3_title[lang] }}</div>
                        </div>
                        <div class="instruction__block__text-block__description">{{ translates[langUrl].step3_description[lang] }}</div>
                    </div>
                </div>
                <div class="instruction__block__picture instruction__block__picture__review"></div>
            </div>
            <div class="instruction__block">
                <div class="instruction__block__text-block">
                    <div v-if="isDesktop" class="instruction__block__text-block__step">4</div>
                    <div class="instruction__block__text-block__text">
                        <div style="display: flex">
                            <div v-if="!isDesktop" class="instruction__block__text-block__step">4</div>
                            <div class="instruction__block__text-block__title">{{ translates[langUrl].step4_title[lang] }}</div>
                        </div>
                        <div class="instruction__block__text-block__description">{{ translates[langUrl].step4_description[lang] }}</div>
                    </div>
                </div>
                <div class="instruction__block__picture instruction__block__picture__tour"></div>
            </div>
            <div class="instruction__block">
                <div class="instruction__block__text-block">
                    <div v-if="isDesktop" class="instruction__block__text-block__step">5</div>
                    <div class="instruction__block__text-block__text">
                        <div style="display: flex">
                            <div v-if="!isDesktop" class="instruction__block__text-block__step">5</div>
                            <div class="instruction__block__text-block__title">{{ translates[langUrl].step5_title[lang] }}</div>
                        </div>
                        <div class="instruction__block__text-block__description">{{ translates[langUrl].step5_description[lang] }}</div>
                    </div>
                </div>
                <div class="instruction__block__picture instruction__block__picture__enjoy"></div>
            </div>
            <div class="instruction__block">
                <div class="instruction__block__text-block">
                    <div v-if="isDesktop" class="instruction__block__text-block__step">6</div>
                    <div class="instruction__block__text-block__text">
                        <div style="display: flex">
                            <div v-if="!isDesktop" class="instruction__block__text-block__step">6</div>
                            <div class="instruction__block__text-block__title">{{ translates[langUrl].step6_title[lang] }}</div>
                        </div>
                        <div class="instruction__block__text-block__description">{{ translates[langUrl].step6_description[lang] }}</div>
                        <BaseButton
                            v-if="isDesktop"
                            class="instruction__block__text-block__button"
                            @click="goTo('trip-list')"
                        >{{ translates[langUrl].button[lang] }}</BaseButton>
                    </div>
                </div>
                <div class="instruction__block__picture instruction__block__picture__repeat"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc } from '@/helpers';
    import moment from '@/plugins/moment';
    moment.locale('ru');
    
    import BaseButton from '../components/common/BaseButton.vue';

    export default {
        name: 'Instruction',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle[this.lang]
            }
        },
        components: {
            BaseButton,
        },
        data: () => ({
            isMobile,
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            langUrl: '/instruction'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            this.isDesktop = !isMobile();
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                    await this.$router.push({ name });
            },
            async back() {
                await this.$router.back();
            },
        },
    };
</script>

<style lang="scss">
.instruction {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 350px;
        background: url(../assets/Instruction.png) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 254px;
            background-position: center;
        }
        // &__opacity {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background: #543919;
        //     opacity: 0.5;
        // }
        &__container {
        position: absolute;
        width: 100%;
        height: 350px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 254px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1280px;
                margin: 120px auto 0;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    text-align: left;
                    margin-top: 115px;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-size: 20px;
                    text-align: left;
                }
            }
        }
        &__action {
            position: absolute;
            display: flex;
            height: 32px;
            width: 32px;
            top: 40px;
            background: #FFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
            }
            &_back {
                left: 20px;
            }
            &__icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
        margin-top: 60px;
        background-image: url(../assets/instruction/Main-line.jpg);
        background-position-x: 15px;
        background-position-y: 75px;
        @media all and (max-width: 768px) {
            background-image: none;    
        }
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 120px;
        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 50px;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__text-block {
            display: flex;
            flex-direction: row;
            width: 500px;
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 12px;
            }
            &__step {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 46px;
                height: 46px;
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
                border-radius: 600px;
                margin-right: 28px;
                font-weight: 700;
                font-size: 24px;
                line-height: 143.02%;
                color: #FFFFFF;
                @media all and (max-width: 768px) {
                    min-width: 31px;
                    height: 31px;
                    font-size: 16px;
                    line-height: 143.02%;
                    margin-right: 16px;
                }
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: #273155;
                margin-bottom: 17px;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    line-height: 130%;
                }
            }
            &__description {
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 28px;
                @media all and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 150%;
                }
            }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
        &__picture {
            min-width: 700px;
            height: 358px;
            background-size: cover;
            border-radius: 10px;
            @media all and (max-width: 1200px) {
                min-width: 500px;
                height: 260px;
            }
            @media all and (max-width: 950px) {
                min-width: 400px;
                height: 200px;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                height: 220px;
            }
            &__main {
                background-image: url(../assets/instruction/Screen_main.jpg);
            }
            &__chat {
                background-image: url(../assets/instruction/Screen_chat.jpg);
            }
            &__review {
                background-image: url(../assets/instruction/Screen_review.jpg);
            }
            &__tour {
                background-image: url(../assets/instruction/Screen_tour.jpg);
            }
            &__enjoy {
                background-image: url(../assets/instruction/Screen_enjoy.jpg);
            }
            &__repeat {
                background-image: url(../assets/instruction/Screen_repeat.jpg);
            }
        }
    }
}

</style>